<template>
    <div>
      <v-card :loading="loading">
        <v-toolbar
            color="#4ECDC4"
            dark
            flat
        >

        <span class="text -h6">{{name}}</span>
    
          <v-spacer></v-spacer>
          <v-btn 
            icon
            @click="close"
          > 
          <v-icon>{{icons.close}}</v-icon>
        </v-btn>
        </v-toolbar>
        <div  class="pa-2 pt-0"> 
          <v-btn v-if="type=='navi'" color="narekomu" outlined class="white--text ma-1ml-0" @click="thumbnailChange()">サムネイルの変更</v-btn>
          <v-btn v-if="type=='navi'" color="narekomu" outlined class="white--text ma-1" @click="downloadImg()">ダウンロード</v-btn>
        <v-card-text class="blue-grey lighten-5  overflow-y-auto ma-0 pa-0"  >
          <v-img v-if="type=='navi'" :src="url" contain :aspect-ratio="16/9"  max-width="1000px" width="100%"  @contextmenu="show" ></v-img>
          <v-img v-else-if="!loading" :src="url" contain :aspect-ratio="$vuetify.breakpoint.xs?6/9 :16/11"  max-width="1000px" width="100%"   @contextmenu="show"></v-img>
        </v-card-text>
        </div>
      
      </v-card>
      <v-menu
        v-model="showMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
      >
        <v-list>
          <v-list-item>
            <v-btn text @click="downloadImg()">画像を保存する</v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    
      </div>
      

</template> 

<script>
import { mdiClose } from '@mdi/js'


export default {
  name: 'DialogImageView',
  props:['url','loading',"name","type","item"],
  data () {
    return {
      icons:{"close":mdiClose},
      showMenu: false,
      x: 0,
      y: 0,
    }
  },

  watch:{
    url (){
      console.log(this.url)
    }
  },

  methods: {

    close (){
      this.$emit('closeEmit')
    },

    show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.showMenu = true
        })
    },

    downloadImg(){
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.href = this.url;
      a.download = this.name;
      a.click();
      a.remove();
    },

    thumbnailChange(){
      this.$emit('thumbnailChangeEmit',this.item)
    }
 
  },
}

</script>
